import { Injectable, OnInit } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { WeightTicketsComponent } from 'src/app/shared/weight-tickets/weight-tickets.component';
import { ListResponse } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { City, PropertyDocument } from 'src/lib/newBackendTypes';
import { WeightTicket } from 'src/lib/newBackendTypes/weightTicket';
import { SpinnerService } from './spinner.service';
import { ThalosApiService } from './thalos-api.service';
import { randomFetchSynonym } from 'src/lib/uiConstants';
import { GraphqlService } from './graphql.service';
import { getGraphqlRequestQuery } from 'src/lib/graphql/graphQlFunctions';
import { ExistingGraphql, GraphqlNames } from 'src/lib/graphql/graphQlEnums';
import { basicCityGraphqlRequest } from 'src/lib/graphql/entities/city.graphql';

@Injectable()
export class WeightTicketService implements OnInit {
  constructor(
    private dialogService: DialogService,
    private api: ThalosApiService,
    private spinnerService: SpinnerService,
    private graphqlService: GraphqlService,
  ) {}

  ngOnInit() {}

  getWeightTickets(shipments: PropertyDocument[]) {
    let rId: string;
    if (!shipments || shipments.length === 0) return of([]);
    return of(null).pipe(
      tap((_) => {
        rId = this.spinnerService.startRequest(randomFetchSynonym() + ' Weight Tickets');
      }),
      switchMap((_) => {
        return this.api.rpc<ListResponse<WeightTicket>>(endpoints.listWeightTickets, { filters: { chunkKey: shipments.map((s) => s.id) } }, { list: [], count: 0 });
      }),
      map((res) => res.list),
      tap((_) => {
        this.spinnerService.completeRequest(rId);
      }),
    );
  }

  open(shipments: PropertyDocument[]): Observable<WeightTicket[] | null> {
    let tickets$ = this.getWeightTickets(shipments);
    let component: WeightTicketsComponent;
    return tickets$.pipe(
      switchMap(async (tickets) => {
        let locationIds: number[] = shipments.flatMap((s) => [s.destinationPortId, s.loadingPortId]).filter((id) => !!id);

        const cities: City[] =
          locationIds.length >= 1
            ? await this.graphqlService
                .query<City[]>({
                  query: getGraphqlRequestQuery<City>(GraphqlNames.simpleCitiesList, basicCityGraphqlRequest({ where: { id: { in: locationIds } } })),
                  graphQlName: ExistingGraphql.places,
                })
                .then((res) => {
                  return res;
                })
            : [];

        return { tickets, cities };
      }),
      switchMap(({ tickets, cities }) => {
        let dialog = this.dialogService.open({
          content: WeightTicketsComponent,
          title: 'Weight Tickets',
          width: 900,
          maxWidth: '100%',
        });
        component = dialog.content.instance;
        component.setShipments(
          shipments.map((s) => {
            return {
              ...s,
              origin: cities.find((c) => c.id === s.loadingPortId),
              destination: cities.find((c) => c.id === s.destinationPortId),
            };
          }),
        );
        component.data = tickets;

        return dialog.result;
      }),
      filter((res) => res !== undefined),
      map((res) => {
        return component.data;
      }),
    );
  }
}
